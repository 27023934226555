/* eslint-disable no-console */

import { register } from 'register-service-worker'
import Swal from 'sweetalert2'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.info(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.info(`Service worker has been registered. url: ${process.env.BASE_URL}service-worker.js`)
    },
    cached () {
      console.info('Content has been cached for offline use.')
    },
    updatefound () {
      console.info('New content is downloading.')
    },
    updated () {
      /**
       * New content is available. Please follow the below 2 steps:
       * 1. Close any open ASOMS websites
       * 2. Open new ASOMS website
       */
      Swal.fire({
        icon: "info",
        html: `
          <div style="text-align: left;line-height:2rem">
            <P><Strong>New content is available. Please follow the below 2 steps:</Strong></p>
            <P><Strong>1. Close any open ASOMS websites</Strong></p>
            <P><Strong>2. Open new ASOMS website</Strong></p>
          </div>
        `,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#4285f4",
        allowOutsideClick: false,
        width: "600px",
      });
    },
    offline () {
      console.info('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
